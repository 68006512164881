import React from 'react';
import '../scss/footerNav.scss';
import PropTypes from 'prop-types';

export default function FooterNav({ links, classes }) {
	return (
		<nav className={`footer-navbar ${classes}`}>
			{links.map(link => {
				const { id, title, route, isDownload } = link;
				return (
					<a className="footer-navbar__item" href={route} key={id} download={isDownload ? true : undefined}>
						{title}
					</a>
				);
			})}
		</nav>
	);
}
FooterNav.propTypes = {
	links: PropTypes.array,
	classes: PropTypes.string,
};
