import React from 'react';
import FooterNav from './items/footerNav';
import Socials from './items/socials';
import Copy from './items/copy';
import './scss/footer.scss';
import behance from '../../assets/svg/SocialsIcon/ic_behance.svg';
import inst from '../../assets/svg/SocialsIcon/ic_instagram.svg';
import linkedIn from '../../assets/svg/SocialsIcon/ic_linkedin.svg';
import medium from '../../assets/svg/SocialsIcon/ic_medium.svg';

export default function Footer() {
	return (
		<footer className="footer">
			<div className="footer-content">
				<div className="container">
					<div className="footer-nav">
						<FooterNav
							links={[
								{
									title: 'Contact Us',
									route: '/contact-us',
									id: 10,
								},
								{
									title: 'Terms',
									route: '/non-poaching_agreement.docx',
									id: 11,
									isDownload: true,
								},
								{
									title: 'Privacy policy',
									route: '/',
									id: 12,
								},
							]}
							classes="main"
						/>
						<Socials
							fill="secondary"
							social={[
								{
									src: behance,
									title: 'behance',
									route: 'https://www.behance.net/asol',
									id: 6,
								},
								{
									src: inst,
									title: 'instagram',
									route: 'https://www.instagram.com/a_sol.group/',
									id: 7,
								},
								{
									src: linkedIn,
									title: 'linkedIn',
									route: 'https://www.linkedin.com/company/a-sol/',
									id: 8,
								},
								{
									src: medium,
									title: 'medium',
									route: 'https://medium.com/@a-sol',
									id: 9,
								},
							]}
						/>
						<Copy />
					</div>
				</div>
			</div>
		</footer>
	);
}
