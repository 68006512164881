import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../scss/navbar.scss';
import classNames from 'classnames';
import { links } from '../../../consts';
import Copy from '../../footer/items/copy';
import Socials from '../../footer/items/socials';
import FooterNav from '../../footer/items/footerNav';
import behance from '../../../assets/svg/SocialsIcon/ic_behance.svg';
import inst from '../../../assets/svg/SocialsIcon/ic_instagram.svg';
import linkedIn from '../../../assets/svg/SocialsIcon/ic_linkedin.svg';
import medium from '../../../assets/svg/SocialsIcon/ic_medium.svg';

export default function NavBar({ isActive, handleClick }) {
	return (
		<nav className="navbar">
			<div className={classNames('navbar-btn', { active: isActive })} onClick={handleClick}>
				<span />
			</div>
			<div className={classNames('navbar-menu', { active: isActive })}>
				<div className="navbar-menu__wrapper">
					<div className="navbar-menu__mobile">
						{links.map(link => {
							const { id, title, route } = link;

							return (
								<NavLink className="navbar-menu__item" to={route} onClick={isActive && handleClick} key={id}>
									{title}
								</NavLink>
							);
						})}
					</div>
					<div className="navbar-menu__footer">
						<FooterNav
							links={[
								{
									title: 'Terms',
									route: '/non-poaching_agreement.docx',
									id: 11,
									isDownload: true,
								},
								{
									title: 'Privacy policy',
									route: '/',
									id: 12,
								},
							]}
							classes="mobile"
						/>
						<Socials
							classes="mobile"
							fill="burger"
							social={[
								{
									src: behance,
									title: 'behance',
									route: 'https://www.behance.net/asol',
									id: 6,
								},
								{
									src: inst,
									title: 'instagram',
									route: 'https://www.instagram.com/a_sol.group/',
									id: 7,
								},
								{
									src: linkedIn,
									title: 'linkedIn',
									route: 'https://www.linkedin.com/company/a-sol/',
									id: 8,
								},
								{
									src: medium,
									title: 'medium',
									route: 'https://medium.com/@a-sol',
									id: 9,
								},
							]}
						/>
						<Copy classes="mobile" />
					</div>
				</div>
			</div>
		</nav>
	);
}

NavBar.propTypes = {
	isActive: PropTypes.bool,
	handleClick: PropTypes.func,
};
